<template>
  <div class="login flex">
    <div class="topRight common">
      <img src="../../assets/img/animalTop.png" alt="">
    </div>
    <div class="bottomLeft common">
      <img src="../../assets/img/animalBottom.png" alt="">
    </div>
    <div class="loginContent">
      <div class="content">
        <div class="logo"><img src="../../assets/img/ysLogo.png" alt=""></div>
        <div class="start flex">
          <div class="left">
<!--            <img src="../../assets/img/logo.png" alt="" class="logoT">-->
            <h1>{{ $store.state.setting.systemName }}</h1>
            <h4>{{ $store.state.setting.enName }}</h4>
            <el-form ref="form" :model="formInline" class="formInline">
              <template v-if="show">
                <el-form-item prop="preUsername">
                  <el-input prefix-icon="el-icon-mobile-phone" placeholder="请输入用户名"
                            v-model="formInline.preUsername" clearable></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input placeholder="请输入密码" prefix-icon="el-icon-lock" type="password"
                            v-model="formInline.password" clearable></el-input>
                </el-form-item>
                <el-form-item prop="name">
                  <el-input prefix-icon="el-icon-office-building" placeholder="请输入公司名称"
                            v-model="formInline.name" clearable></el-input>
                </el-form-item>
                <el-form-item prop="registAddr">
                  <el-input prefix-icon="el-icon-map-location" placeholder="请输入注册地址"
                            v-model="formInline.registAddr" clearable></el-input>
                </el-form-item>
                <el-form-item class="btnGroup">
                  <el-button type="primary" size="small" @click="next">下一步</el-button>
                  <el-link :underline="false" href="welcome">返回</el-link>
                </el-form-item>

              </template>
              <template v-if="!show">
                <el-form-item prop="officeAddr">
                  <el-input prefix-icon="el-icon-location-information" placeholder="请输入办公地址"
                            v-model="formInline.officeAddr" clearable></el-input>
                </el-form-item>
                <el-form-item prop="contact">
                  <el-input prefix-icon="el-icon-s-custom" placeholder="请输入联系人姓名"
                            v-model="formInline.contact" clearable></el-input>
                </el-form-item>
                <el-form-item prop="phone">
                  <el-input prefix-icon="el-icon-mobile" placeholder="请输入联系人电话"
                            v-model="formInline.phone" clearable></el-input>
                </el-form-item>
                <el-form-item class="btnGroup">
                  <el-button type="primary" size="small" @click="submitForm">注册</el-button>
                  <el-link :underline="false" @click="show = true">上一步</el-link>
                  <el-link :underline="false" href="welcome">返回</el-link>
                </el-form-item>
              </template>
            </el-form>
            <div class="littleFeet">
              <img src="../../assets/img/feetS.png" alt="" class="feetImgS ">
              <img src="../../assets/img/feetL.png" alt="" class="feetImgL">
            </div>
          </div>
          <div class="right">
            <img src="../../assets/img/animalSys.png" alt="" width="100%">
            <img src="../../assets/img/mouse.png" alt="" width="90%" class="mouse">
          </div>
        </div>
      </div>
      <div class="footer" v-html=" $store.state.setting.copyright"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "regist",
  data() {
    return {
      show: true,
      formInline: {
        preUsername: '',
        password: '',
        name: '',
        registAddr: '',
        officeAddr: '',
        phone: '',
        contact: '',
      },
      rules: {
        officeAddr: [
          {required: true, message: '请输入办公地址', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'}
        ],
        contact: [
          {required: true, message: '请输入联系人姓名', trigger: 'blur'}
        ],
      },
      visible: false
    }
  },
  mounted() {

  },
  methods: {
    next() {
      if ($.trim(this.formInline.preUsername).length === 0) {
        this.$message.warning("请输入用户名");
        return;
      }
      if ($.trim(this.formInline.password).length === 0) {
        this.$message.warning("请输入密码");
        return;
      }
      if ($.trim(this.formInline.name).length === 0) {
        this.$message.warning("请输入公司名称");
        return;
      }
      if ($.trim(this.formInline.registAddr).length === 0) {
        this.$message.warning("请输入注册地址");
        return;
      }
      this.show = false
    },
    submitForm() {
      this.$post('regist', this.formInline).then(() => {
        this.$message.success('注册成功')
        setTimeout(() => {
          this.$router.push({path: '/login'})
        }, 300)
      }).catch(() => {
        this.$message.error('抱歉，注册账号失败')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/login";
</style>
